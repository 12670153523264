import loadable from '@loadable/component';

const LoadableGtmHead = loadable(() =>
  import('./GtmHead').catch(() => {
    // Fallback in case of import error or timeout
    console.error('Failed to load GTM head script.');
    return () => null;
  })
);

export default LoadableGtmHead;