import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const NewLogo = () => (
  <StaticImage
    src="../../images/logo/new-command-access-logo.png"
    width={300}
    quality={95}
    formats={["AUTO", "WEBP"]}
    alt="Command Access logo"
  />
)

export default NewLogo