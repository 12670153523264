import React from "react"
import { graphql } from "gatsby"
import { getImage, getSrc } from "gatsby-plugin-image"
import { RichText, Date } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';
import styled from 'styled-components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import PageHeaderImageBgNew from '../../components/headers/PageHeaderImageBgNew'
import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const BlogPostTemplate = ({ data }) => {
  const doc = data.prismicBlogPost
  const imgSrc = getSrc(doc.data.post_hero_background)
  const imgHero = getImage(doc.data.post_hero_background)
  const datePosted = new Date(doc.data.date_published)
  const formattedDate = datePosted.toLocaleDateString('en-US',{
    year: "numeric",
    month: "short",
    day: "numeric"
  })

  return (
    <MainContainer>
      <HeadDataNew
        title={doc.data.meta_title}
        description={doc.data.meta_description}
        noIndex={doc.data.post_no_index ? true : false}
        image={imgSrc}
      />

      <PageHeaderImageBgNew imageData={imgHero} height={600} position={doc.data.post_hero_background_position}>
        <StyledDiv className='w-11/12 xl:max-w-screen-lg flex flex-col items-center'>
          <h1 className='font-body font-extrabold text-3xl md:text-4xl xl:text-5xl text-white text-center text-shadow tracking-wider'>
            {doc.data.post_title}
          </h1>
        </StyledDiv>
      </PageHeaderImageBgNew>

      <div className='bg-white pt-12 pb-16 md:min-h-screen'>
        <div className='w-11/12 md:max-w-screen-lg mx-auto'>
          <p className="font-body text-xs text-gray-900 uppercase">Published on {formattedDate}</p>

          <div className="blog-content">
            <RichText
              render={doc.data.post_content.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
        </div>
      </div>
    </MainContainer>
  )
}

export default withPrismicPreview(BlogPostTemplate)

export const BlogPostTemplateNewQuery = graphql`
  query($id: String!) {
    prismicBlogPost(id: { eq: $id }) {
      _previewable
      uid
      data {
        post_title
        meta_title
        meta_description
        post_hero_background {
          gatsbyImageData(
            width: 1920
          )
        }
        post_hero_background_position
        post_no_index
        post_excerpt
        date_published
        post_content {
          richText
        }
      }
    }
  }
`