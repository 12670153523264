import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import Favicon from './favicon.png'

const HeadDataNew = ({ title, description, keywords, image, article, product, noIndex }) => {
  const { pathname } = useLocation()
  const data = useStaticQuery(graphql`
    query HeadDataNew {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: siteUrl
        }
      }
      defaultImage: file(relativePath: { eq: "defaults/og-cat.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }
  `)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
  } = data.site.siteMetadata

  const defaultImage = getSrc(data.defaultImage)

  const HeadDataNew = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    keywords: keywords,
  }

  return (
    <Helmet title={HeadDataNew.title} titleTemplate={product ? null : titleTemplate}>
      <meta name="description" content={HeadDataNew.description} />
      <meta name="image" content={HeadDataNew.image} />
      {keywords ? <meta name="keywords" content={HeadDataNew.keywords} /> : null}

      {HeadDataNew.url && <meta property="og:url" content={HeadDataNew.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {HeadDataNew.title && <meta property="og:title" content={HeadDataNew.title} />}

      {HeadDataNew.description && (
        <meta property="og:description" content={HeadDataNew.description} />
      )}

      {HeadDataNew.image && <meta property="og:image" content={HeadDataNew.image} />}

      {noIndex ? <meta name="robots" content="noindex" /> : null}

      <link rel="preload" href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" as="style" onload="this.onload=null;this.rel='stylesheet'" />
      <noscript>{`<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" />`}</noscript>

      <link rel="icon" type="image/png" href={Favicon} />

      <link rel="preconnect" href="https://www.commandaccess.com" />
      <link rel="preconnect" href="https://commandaccess.prismic.io" crossorigin />
      <link rel="preconnect" href="https://www.googletagmanager.com" crossorigin />
      <link rel="preconnect" href="https://cdn.userway.org" crossorigin />
      <link rel="preload" href="https://www.commandaccess.com/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js" as="script" />
      <link rel="preload" href="https://www.commandaccess.com/app-1de94cdc76e7b6a59b6c.js" as="script" />
      <link rel="preload" href="https://www.commandaccess.com/app-27cb066b0c4fe1b2c98d.js" as="script" />
      <link rel="preload" href="https://js.usemessages.com/conversations-embed.js" as="script" />
      <link rel="preload" href="https://static.hsappstatic.net/conversations-embed/static-1.15669/bundles/project.js.map" as="script" type="application/json" />      
      <link rel="preload" href="https://www.commandaccess.com/static/bebas-neue-latin-400-normal-2722c396c28250094a8cdb3e71c4bd87.woff2" as="font" type="font/woff2" crossorigin />
      <link rel="preload" href="https://commandaccess.prismic.io/prismic-toolbar/4.0.9/iframe.html" as="iframe" crossorigin />

      <meta name="twitter:card" content="summary_large_image" />

      {HeadDataNew.title && <meta name="twitter:title" content={HeadDataNew.title} />}

      {HeadDataNew.description && (
        <meta name="twitter:description" content={HeadDataNew.description} />
      )}

      {HeadDataNew.image && <meta name="twitter:image" content={HeadDataNew.image} />}

      <script type="application/ld+json">
        {`
          {
            "@context" : "http://schema.org",
            "@type" : "LocalBusiness",
            "name" : "Command Access Technologies",
            "openingHours": "Mo,Tu,We,Th,Fr 07:30-16:30",
            "telephone" : "888-622-2377",
            "email" : "orders@commandaccess.com",
            "address" : {
              "@type" : "PostalAddress",
              "streetAddress" : "22901 La Palma Ave.",
              "addressLocality" : "Yorba Linda",
              "addressRegion" : "CA",
              "postalCode" : "92887"
            },
            "image" : "${defaultImage}",
            "priceRange" : "$$$"
          }
        `}
      </script>

      <script src="https://cdn.usefathom.com/script.js" data-site="LXYHZCIT" defer></script>      
    </Helmet>
  )
}

// Fathom script: <script src="https://cdn.usefathom.com/script.js" data-site="LXYHZCIT" defer></script>

export default HeadDataNew

HeadDataNew.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

HeadDataNew.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}