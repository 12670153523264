import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const ContactImage = () => (
  <StaticImage
    src="../../images/header/contact-image.png"
    width={300}
    quality={95}
    formats={["AUTO", "WEBP"]}
    alt="Graphic image of phone number"
    className='w-48'
  />
)

export default ContactImage