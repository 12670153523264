import { componentResolverFromMap } from "gatsby-plugin-prismic-previews";

import { linkResolver } from "./link-resolver";

import PostTemplate from "../../src/pages/blog/{prismicBlogPost.uid}";
import CampaignLandingPageTemplate from "../../src/pages/campaigns/{prismicCampaignLandingPage.uid}";
import CampaignLandingPageVersionTwoTemplate from "../../src/pages/campaigns/{prismicCampaignLandingPageVersionTwo.uid}";
import DistributorProductLandingPageTemplate from "../../src/pages/distributors/{prismicNewProductLandingPage.uid}";
import SalesRepProductLandingPageTemplate from "../../src/pages/sales-representatives/{prismicNewProductLandingPage.uid}";
import ExitTrimsProductTemplate from "../../src/pages/exit-trims/{prismicProduct.uid}";
import MortiseLocksProductTemplate from "../../src/pages/mortise-locks/{prismicProduct.uid}";
import ExitDeviceKitsProductTemplate from "../../src/pages/exit-device-kits/{prismicProduct.uid}";
import LowEnergyOperatorProductTemplate from "../../src/pages/low-energy-operator/{prismicProduct.uid}";
import CylindricalLocksProductTemplate from "../../src/pages/cylindrical-locks/{prismicProduct.uid}";
import ExitDevicesProductTemplate from "../../src/pages/exit-devices/{prismicProduct.uid}";
import PowerSuppliesProductTemplate from "../../src/pages/power-supplies/{prismicProduct.uid}";
import PowerTransfersProductTemplate from "../../src/pages/power-transfers/{prismicProduct.uid}";
import AccessoriesProductTemplate from "../../src/pages/accessories/{prismicProduct.uid}";

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      blog_post: PostTemplate,
      campaign_landing_page: CampaignLandingPageTemplate,
      campaign_landing_page_version_two: CampaignLandingPageVersionTwoTemplate,
      new_product_landing_page: DistributorProductLandingPageTemplate,
      new_product_landing_page: SalesRepProductLandingPageTemplate,
      product: ExitTrimsProductTemplate,
      product: MortiseLocksProductTemplate,
      product: ExitDeviceKitsProductTemplate,
      product: LowEnergyOperatorProductTemplate,
      product: CylindricalLocksProductTemplate,
      product: ExitDevicesProductTemplate,
      product: PowerSuppliesProductTemplate,
      product: PowerTransfersProductTemplate,
      product: AccessoriesProductTemplate,
      // Add more mappings as needed for other templates
    }),
  },
];