import React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import YoutubePlayerLite from "../../components/common/YoutubePlayerLite"
import LoomPlayerEmbed from "../../components/common/LoomPlayerEmbed"

const SalesRepProductLandingPageTemplate = ({ data }) => {
  const product = data.prismicNewProductLandingPage
  const imageData = getImage(product.data.product_image)
  const imgSrc = getSrc(product.data.product_image)

  return (
    <MainContainer>
      <HeadDataNew
        title={product.data.product_name}
        description={product.data.ales_rep_resources_section_description}
        image={imgSrc ? imgSrc : null}
      />

      
      <div className='bg-white py-12'>
        <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto'>
          <h1 className='font-body text-4xl md:text-5xl text-red-900 text-center font-extrabold tracking-wider leading-tight mb-6'>{product.data.product_name}</h1>

          <div>
            <GatsbyImage
              image={imageData}
              alt={imageData.alt}
            />
          </div>
        </div>

        <div className='w-11/12 xl:max-w-screen-lg mx-auto mt-16'>
          <h2 className='font-body text-3xl md:text-4xl text-gray-900 font-bold'>{product.data.sales_rep_resources_section_title}</h2>
          <p className='font-body text-xl md:text-2xl text-gray-900 font-medium mt-6'>{product.data.sales_rep_resources_section_description}</p>
        </div>

        {product.data.common_resources_files.length < 3
          ?
          <div className='w-11/12 xl:max-w-screen-lg grid grid-cols-1 lg:grid-cols-2 gap-24 mx-auto mt-16'>
            {product.data.common_resources_files.map( file => {
              const imageFile = getImage(file.file_image)
              return (
                <>
                  {file.for_sales_rep
                    ?
                    <div className='flex flex-col items-center justify-center'>
                      <a href={file.file_link.url} target='_blank' rel='noopener noreferrer'>
                        <GatsbyImage
                          image={imageFile}
                          alt={imageFile.alt}
                          className='border-2 hover:border-gray-900 hover:shadow-lg'
                        />
                      </a>

                      <h3 className='font-body text-xl md:text-2xl text-gray-900 font-semibold mt-4'>{file.file_name}</h3>

                      <div className='lg:h-20 flex items-center justify-center space-x-2 mt-4'>
                        {file.file_link.url
                          ?
                          <a href={file.file_link.url} target='_blank' rel='noopener noreferrer'>
                            <StaticImage
                              src="../../images/sections/downloading.png"
                              width={50}
                              quality={95}
                              formats={["AUTO", "WEBP"]}
                              alt="Download"
                              className='border rounded-full hover:border-red-900'
                            />
                          </a>
                          :
                          null
                        }

                        {/* Use https://mailtolinkgenerator.com/ to generate mailto. */}
                        {file.mail_to_link.url
                          ?
                          <a href={file.mail_to_link.url}>
                            <StaticImage
                              src="../../images/sections/email.png"
                              width={50}
                              quality={95}
                              formats={["AUTO", "WEBP"]}
                              alt="Email MLRK1-JAC12 Insert Instructions"
                              className='border rounded-full hover:border-red-900'
                            />
                          </a>
                          :
                          null
                        }
                      </div>
                    </div>
                    :
                    null
                  }
                </>
              )
            })}
          </div>
          :
          <div className='w-11/12 xl:max-w-screen-lg grid grid-cols-1 lg:grid-cols-3 gap-24 mx-auto mt-16'>
            {product.data.common_resources_files.map( file => {
              const imageFile = getImage(file.file_image)
              return (
                <>
                  {file.for_sales_rep
                    ?
                    <div className='flex flex-col items-center justify-center'>
                      <div className="lg:h-96">
                        <a href={file.file_link.url} target='_blank' rel='noopener noreferrer'>
                          <GatsbyImage
                            image={imageFile}
                            alt={imageFile.alt}
                            className='border-2 hover:border-gray-900 hover:shadow-lg'
                          />
                        </a>
                      </div>

                      <div className="lg:h-20">
                        <h3 className='font-body text-xl md:text-2xl text-gray-900 font-semibold mt-4'>{file.file_name}</h3>
                      </div>

                      <div className='lg:h-20 flex items-center justify-center space-x-2 mt-4'>
                        {file.file_link.url
                          ?
                          <a href={file.file_link.url} target='_blank' rel='noopener noreferrer'>
                            <StaticImage
                              src="../../images/sections/downloading.png"
                              width={50}
                              quality={95}
                              formats={["AUTO", "WEBP"]}
                              alt="Download"
                              className='border rounded-full hover:border-red-900'
                            />
                          </a>
                          :
                          null
                        }

                        {/* Use https://mailtolinkgenerator.com/ to generate mailto. */}
                        {file.mail_to_link.url
                          ?
                          <a href={file.mail_to_link.url}>
                            <StaticImage
                              src="../../images/sections/email.png"
                              width={50}
                              quality={95}
                              formats={["AUTO", "WEBP"]}
                              alt="Email MLRK1-JAC12 Insert Instructions"
                              className='border rounded-full hover:border-red-900'
                            />
                          </a>
                          :
                          null
                        }
                      </div>
                    </div>
                    :
                    null
                  }
                </>
              )
            })}
          </div>
        }
        
        <div className='w-11/12 xl:max-w-screen-lg mx-auto mt-32 border-t-2 border-red-800 pt-28'>
          <h2 className='font-body text-3xl md:text-4xl text-gray-900 font-bold'>{product.data.sales_rep_videos_section_title}</h2>
          <p className='font-body text-xl md:text-2xl text-gray-900 font-medium mt-6'>{product.data.sales_rep_videos_section_description}</p>
        </div>

        <div className='w-11/12 xl:max-w-screen-lg grid grid-cols-1 lg:grid-cols-2 gap-24 mx-auto mt-16 mb-24'>
          {product.data.common_videos.map( video => {
            return (
              <>
                {video.for_sales_rep
                  ?
                  <div className='flex flex-col items-center justify-center'>
                    {video.video_type === "YouTube"
                      ?
                      <div className='w-full shadow-md'>
                        <YoutubePlayerLite youtubeID={video.youtube_id} />
                      </div>
                      :
                      null
                    }

                    {video.video_type === "Loom"
                      ?
                      <div className='w-full shadow-md'>
                        <LoomPlayerEmbed title={video.video_title} loomEmbedLink={video.loom_embed_link} />
                      </div>
                      :
                      null
                    }

                    <h3 className='font-body text-xl md:text-2xl text-gray-900 font-semibold mt-4'>{video.video_title}</h3>
                  </div>
                  :
                  null
                }
              </>
            )
          })}
        </div>
      </div>

      <div className='bg-gray-200 py-24'>
        <div className='bg-gray-900 w-11/12 xl:max-w-screen-lg flex flex-col md:flex-row items-center justify-center mx-auto py-10 px-4 rounded-2xl md:space-x-6'>
          <p className='font-body text-2xl md:text-3xl text-white text-center font-medium'>Need something else?</p>

          <Link to='/contact'>
          <button className="bg-red-900 hover:bg-red-800 font-display text-white text-2xl uppercase font-bold py-3 px-6 mt-6 md:mt-0 rounded tracking-widest shadow">
            Contact Us Now
          </button>
        </Link>
        </div>
      </div>
    </MainContainer>
  )
}

export default withPrismicPreview(SalesRepProductLandingPageTemplate)

export const saleRepProductLandingPageTemplateQuery = graphql`
  query($id: String!) {
    prismicNewProductLandingPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        product_name
        product_image {
          gatsbyImageData(width: 341)
        }
        sales_rep_resources_section_title
        sales_rep_resources_section_description
        common_resources_files {
          file_image {
            gatsbyImageData(width: 400)
            alt
          }
          file_link {
            url
          }
          file_name
          mail_to_link {
            url
          }
          for_distributor
          for_sales_rep
        }
        sales_rep_videos_section_title
        sales_rep_videos_section_description
        common_videos {
          video_type
          youtube_id
          loom_embed_link
          video_title
          for_distributor
          for_sales_rep
        }
      }
    }
  }
`