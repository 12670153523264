import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

const ContactImageNew = () => {
  const data = useStaticQuery(graphql`
    query ContactImageNewQuery {      
      imageBg: file(relativePath: { eq: "header/contact-image.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 300
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const catPhoneImg = getImage(data.imageBg)
  const catPhoneImgSrc = getSrc(catPhoneImg)

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={catPhoneImgSrc} />
      </Helmet>

      <GatsbyImage
        image={catPhoneImg}
        width="300"
        quality="95"
        alt="Graphic image of phone number"
        className='w-48'
      />
    </>
  )
}

export default ContactImageNew