exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "blog_post":
      return `/blog/${doc.uid}`;

    case "campaign_landing_page":
      return `/campaigns/${doc.uid}`;

    case "campaign_landing_page_version_two":
      return `/campaigns/${doc.uid}`;

    case "new_product_landing_page":
      return `/distributors/${doc.uid}`;

    case "new_product_landing_page":
      return `/sales-representatives/${doc.uid}`;

    case "product":
      return `/exit-trims/${doc.uid}`;

    case "product":
      return `/mortise-locks/${doc.uid}`;

    case "product":
      return `/exit-device-kits/${doc.uid}`;

    case "product":
      return `/low-energy-operator/${doc.uid}`;

    case "product":
      return `/cylindrical-locks/${doc.uid}`;

    case "product":
      return `/exit-devices/${doc.uid}`;

    case "product":
      return `/power-supplies/${doc.uid}`;

    case "product":
        return `/power-transfers/${doc.uid}`;

      case "product":
        return `/accessories/${doc.uid}`;

    default:
      if (!doc.uid) return "/";
      return doc.uid;
  }
};