import React from 'react'
import PropTypes from 'prop-types'

const LoomPlayerEmbed = ({ loomEmbedLink, title }) => (
  <div className='shadow-lg'>
    <iframe
      src={loomEmbedLink ? loomEmbedLink : "https://www.loom.com/embed/79e1fd8840ec4d8f8e1d5668378f228f?sid=bc8e651e-be58-48ea-ad06-3901fcd8a567"}
      width="100%"
      height="261"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      title={title ? title : "Loom Video"}
    ></iframe>
  </div>
)

export default LoomPlayerEmbed

LoomPlayerEmbed.propTypes = {
  loomVideoLink: PropTypes.string,
}