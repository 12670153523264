import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../../utils/htmlSerializer';
import HubspotForm from 'react-hubspot-form'
import { navigate } from '@reach/router'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import PageHeaderImageBgUpdate from '../../components/headers/PageHeaderImageBgUpdate'
import YoutubePlayerLite from "../../components/common/YoutubePlayerLite"

const CampaignLandingPageTemplate = ({ data }) => {
  const product = data.prismicCampaignLandingPage
  const imageData = getImage(product.data.product_image)
  const imgSrc = getSrc(product.data.product_image)
  const imageFirst = getImage(product.data.first_product_section_image_first)
  const imageSecond = getImage(product.data.second_product_section_image)
  const imageCert = getImage(product.data.product_section_certification_badges_image)

  return (
    <MainContainer>
      <HeadDataNew
        title={product.data.meta_title ? product.data.meta_title : product.data.product_name}
        description={product.data.meta_description ? product.data.meta_description : product.data.product_section_overview_description}
        keywords={product.data.meta_keywords ? product.data.meta_keywords : "command access technologies"}
        image={imgSrc ? imgSrc : null}
      />

      
      <div className='bg-white'>
        <PageHeaderImageBgUpdate>
          <div className='w-11/12 xl:max-w-screen-xl flex flex-col md:flex-row items-center'>
            <div className='w-full md:w-1/2'>
              <h1 className='font-body font-bold text-5xl xl:text-7xl text-white text-center md:text-left tracking-wider leading-tight'>
                {product.data.product_name}
              </h1>
            </div>

            <div className='w-full md:w-1/2 flex items-center md:items-end justify-center md:justify-end'>
              <GatsbyImage
                image={imageData}
                alt={imageData.alt}
              />
            </div>
          </div>
        </PageHeaderImageBgUpdate>

        <div className='campaigns-content w-11/12 xl:max-w-screen-xl mx-auto mt-16'>
          <RichText
            render={product.data.product_section_intro.richText}
            htmlSerializer={htmlSerializer}
          />
        </div>

        <div className='w-11/12 xl:max-w-screen-xl mx-auto'>
          <h2 className='font-body text-3xl md:text-4xl text-gray-900 font-bold mt-12 mb-12'>{product.data.product_section_overview_title}</h2>
          <p className='font-body text-xl md:text-2xl text-gray-900 font-medium mt-6'>{product.data.product_section_overview_description}</p>
        </div>

        <div className='w-11/12 xl:max-w-screen-md mx-auto mt-10'>
          <div className='flex flex-col items-center justify-center'>
            <div className='w-full shadow-md'>
              <YoutubePlayerLite youtubeID={product.data.product_section_overview_youtube_id} />
            </div>
          </div>
        </div>

        <div className='bg-white flex items-center justify-center py-24 px-2'>
          <a href='#contact' onClick={() => navigate('#contact')}>
            <button className="bg-red-900 hover:bg-red-800 font-display text-white text-lg uppercase font-bold mt-6 py-3 px-6 rounded tracking-widest mx-auto">
              <p className='font-body text-2xl md:text-3xl text-white text-center font-semibold'>{product.data.cta_button_text}</p>
            </button>
          </a>
        </div>

        {imageFirst
          ?
          <div className='w-11/12 xl:max-w-screen-lg flex flex-col items-center justify-center xl:items-end xl:justify-end mx-auto mt-12'>

            <GatsbyImage
              image={imageFirst}
              alt={imageFirst.alt}
            />

            <p className='font-body text-xl md:text-2xl text-gray-900 text-center md:text-right italic mt-4'>{product.data.first_product_section_image_description}</p>
          </div>
          :
          null
        }

        {imageSecond
          ?
          <div className='w-11/12 xl:max-w-screen-xl grid grid-cols-1 xl:grid-cols-2 gap-24 mx-auto mt-16 pb-16'>
            <div className='flex flex-col items-center justify-center xl:-mt-32'>
              
              <GatsbyImage
                image={imageSecond}
                alt={imageSecond.alt}
              />

              <p className='font-body text-xl md:text-2xl text-gray-900 italic'>{product.data.second_product_section_image_description}</p>
            </div>

            <div className='flex flex-col items-center justify-center xl:items-baseline xl:-mb-44'>
              
              <GatsbyImage
                image={imageCert}
                alt={imageCert.alt}
              />

            </div>
          </div>
          :
          <div className='w-11/12 xl:max-w-screen-xl mx-auto mt-16 pb-16'>
            <div className='flex flex-col items-center justify-center xl:mb-4'>              
              <GatsbyImage
                image={imageCert}
                alt={imageCert.alt}
              />
  
            </div>
          </div>
        }

        <div className='bg-gray-100 pt-10 xl:pt-14 pb-14'>
          <div className='w-11/12 xl:max-w-screen-xl grid grid-cols-1 xl:grid-cols-2 gap-14 xl:gap-24 mx-auto'>
            <div className="campaigns-first-content">
              <RichText
                render={product.data.first_product_section_updates.richText}
                htmlSerializer={htmlSerializer}
              />
            </div>

            <div className='campaigns-second-content border-t-8 xl:border-t-0 xl:border-l-8 border-yellow-400 pt-4 xl:pt-0'>
              <RichText
                render={product.data.second_product_section_updates.richText}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
        </div>

        <div className='bg-yellow-400 py-12'>
          <div className='w-11/12 xl:max-w-screen-lg mx-auto'>
            <h2 className='font-body text-3xl md:text-4xl text-gray-900 text-center font-bold'>{product.data.product_section_yellow_background_text}</h2>
          </div>
        </div>

        <div className='w-11/12 xl:max-w-screen-xl mx-auto pt-20'>
          <h2 className='font-body text-3xl md:text-4xl text-gray-900 font-bold'>{product.data.product_section_installation_title}</h2>
        </div>

        <div className='w-11/12 xl:max-w-screen-md mx-auto mt-16 mb-24'>
          <div className='flex flex-col items-center justify-center'>
            <div className='w-full shadow-md'>
              <YoutubePlayerLite youtubeID={product.data.product_section_installation_youtube_id} />
            </div>
            <p className='font-body text-xl md:text-2xl text-gray-900 font-medium mt-6'>{product.data.product_section_installation_description}</p>
          </div>
        </div>

        <div className='campaigns-content w-11/12 xl:max-w-screen-xl mx-auto mt-16 mb-24'>
          <RichText
            render={product.data.product_section_benefits.richText}
            htmlSerializer={htmlSerializer}
          />
        </div>

        <div id="contact" className='bg-red-900 py-12'>
          <div className='w-11/12 xl:max-w-screen-xl mx-auto'>
            <h2 className='font-body text-3xl md:text-4xl text-white font-bold mb-12'>{product.data.product_section_contact_title}</h2>

            <p className='font-body text-xl md:text-2xl text-white font-bold md:leading-10 mt-6'>{product.data.product_section_contact_description}</p>
          </div>

          <div className='w-11/12 xl:max-w-screen-md mx-auto mt-10 mb-4'>
            <div className='flex flex-col items-center justify-center'>
              <div className='bg-white w-full shadow-md pt-8 px-8 pb-4'>
                <HubspotForm
                  region='na1'
                  portalId='9298297'
                  formId={product.data.product_section_contact_hubspot_form_id}
                  loading={<div className='italic'>Loading contact form...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  )
}

export default withPrismicPreview(CampaignLandingPageTemplate)

export const campaignLandingPageTemplateQuery = graphql`
  query($id: String!) {
    prismicCampaignLandingPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        product_name
        meta_title
        meta_description
        meta_keywords
        product_image {
          gatsbyImageData(width: 420)
          alt
        }
        product_section_intro {
          richText
        }
        product_section_overview_title
        product_section_overview_description
        product_section_overview_youtube_id
        cta_button_text
        first_product_section_image_first {
          gatsbyImageData(width: 1024)
          alt
        }
        first_product_section_image_description
        second_product_section_image {
          gatsbyImageData(width: 420)
          alt
        }
        second_product_section_image_description
        product_section_certification_badges_image  {
          gatsbyImageData(width: 420)
          alt
        }
        first_product_section_updates {
          richText
        }
        second_product_section_updates {
          richText
        }
        product_section_yellow_background_text
        product_section_installation_title
        product_section_installation_description
        product_section_installation_youtube_id
        product_section_benefits {
          richText
        }
        product_section_contact_title
        product_section_contact_description
        product_section_contact_hubspot_form_id
      }
    }
  }
`