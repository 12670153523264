import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadableGtmHead from './GtmHeadLoadable';
import LoadableGtmBody from './GtmBodyLoadable';
import HeaderNavNewVersion from '../navigation/HeaderNavNewVersion';
import FooterNavNewUpdateVersion from '../navigation/FooterNavNewUpdateVersion';

class MainContainer extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="relative">
        {/* Lazy-load GTM script in head section */}
        <LoadableGtmHead gtmId="GTM-M7VJ3PV" />

        <HeaderNavNewVersion />

        {children}

        <FooterNavNewUpdateVersion />

        {/* Lazy-load GTM script in body section */}
        <LoadableGtmBody gtmId="GTM-M7VJ3PV" />
      </div>
    );
  }
}

MainContainer.propTypes = {
  children: PropTypes.node,
};

export default MainContainer;