import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PageHeaderImageBgUpdate = ({ children, imageData, opacity, height, position }) => {
  const data = useStaticQuery(graphql`
    query PageHeaderImageBgUpdateQuery {
      imageBg: file(relativePath: { eq: "header/hero-image-background.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 877)
        }
      }
    }
  `);

  const imageBg = getImage(data.imageBg);

  return (
    <div className="grid relative mb-3" style={{ position: 'relative' }}>
      <GatsbyImage
        style={{
          gridArea: '1/1',
          objectFit: 'cover',
          opacity: opacity ? opacity : 'none',
          width: '100%',
          height: height ? height : 400,
        }}
        alt=""
        image={imageData ? imageData : imageBg}
        formats={['auto', 'webp', 'avif']}
        width={1366}
        objectPosition={position ? position : 'top center'}
      />
      <div
        style={{
          // Apply the gradient overlay here
          position: 'absolute',
          top: 0,
          left: '50%', // Start the gradient from the middle of the image
          transform: 'translateX(-50%)', // Center the gradient horizontally
          width: '100%', // Make sure the gradient covers the whole image
          height: '100%', // Make sure the gradient covers the whole image
          backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
          content: '', // This is important for pseudo-elements
        }}
      ></div>
      <div
        style={{
          gridArea: '1/1',
          position: 'relative',
          placeItems: 'center',
          display: 'grid',
          zIndex: 2,
        }}
      >
        {children}
      </div>
    </div>
  );
};

PageHeaderImageBgUpdate.propTypes = {
  children: PropTypes.node,
  imageData: PropTypes.object,
};

export default PageHeaderImageBgUpdate;