import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

const UpdateLogoNew = () => {
  const data = useStaticQuery(graphql`
    query UpdateLogoNewQuery {      
      imageBg: file(relativePath: { eq: "logo/new-command-access-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 300
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const catLogo = getImage(data.imageBg)
  const catLogoSrc = getSrc(catLogo)

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={catLogoSrc} />
      </Helmet>

      <GatsbyImage
        image={catLogo}
        width="300"
        quality="95"
        alt="Command Access logo"
      />
    </>
  )
}

export default UpdateLogoNew