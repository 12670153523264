import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../../utils/htmlSerializer';
import HubspotForm from 'react-hubspot-form'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import PageHeaderImageBgNewUpdate from "../../components/headers/PageHeaderImageBgNewUpdate";
import YoutubePlayerLite from "../../components/common/YoutubePlayerLite"

const CampaignLandingPageVersionTwoTemplate = ({ data }) => {
  const product = data.prismicCampaignLandingPageVersionTwo
  const imageData = getImage(product.data.hero_product_image)
  const imgSrc = getSrc(product.data.hero_product_image)
  const imageBg = getImage(product.data.hero_background_image)
  const imageCert = getImage(product.data.intro_badges)
  const imageIntro = getImage(product.data.intro_product_image)
  const imageFeatures = getImage(product.data.features_section_image)
  const imageFirst = getImage(product.data.first_question_image)
  const imageSecond = getImage(product.data.second_question_image)

  return (
    <MainContainer>
      <HeadDataNew
        title={product.data.meta_title ? product.data.meta_title : product.data.hero_title}
        description={product.data.meta_description ? product.data.meta_description : product.data.hero_sub_title}
        image={imgSrc ? imgSrc : null}
      />

      
      <div className='bg-white'>
        <PageHeaderImageBgNewUpdate imageData={imageBg}>
          <div className='w-11/12 xl:max-w-screen-xl flex flex-col md:flex-row items-center'>
            <div className='w-full md:w-1/2'>
              <h1 className='font-body font-bold text-6xl xl:text-8xl text-white text-center md:text-left tracking-wider leading-tight'>
                {product.data.hero_title ? product.data.hero_title : "Title"}
              </h1>

              <div className='w-full md:w-10/12'>
                <h2 className='font-body text-2xl xl:text-4xl text-white text-center md:text-left mt-4'>{product.data.hero_sub_title ? product.data.hero_sub_title : "Sub Title"}</h2>
              </div>

              <div className='w-full flex items-center justify-center md:items-start md:justify-start'>
                <a
                  href={product.data.hero_button_link.url ? product.data.hero_button_link.url : "https://www.commandaccess.com/contact"}
                  target={product.data.hero_button_link.target === "_blank" ? '_blank' : '_self'}
                  rel='noopener noreferrer'
                >
                  <button className="bg-site-yellow hover:bg-yellow-500 font-body font-medium text-gray-900 text-xl mt-10 py-3 px-6 rounded">
                      {product.data.hero_button_text ? product.data.hero_button_text : "Button Text"}
                  </button>
                </a>
              </div>
            </div>

            <div className='w-full md:w-1/2 flex items-center md:items-end jusitfy-center md:justify-end'>
              <GatsbyImage
                image={imageData}
                alt={imageData.alt ? imageData.alt : "Hero image"}
              />
            </div>
          </div>
        </PageHeaderImageBgNewUpdate>

        <div className='w-11/12 xl:max-w-screen-xl flex flex-col xl:flex-row items-center xl:items-start mx-auto py-4 xl:py-16'>
          <div className='w-full xl:w-8/12'>
            <GatsbyImage
              image={imageIntro}
              alt={imageIntro.alt ? imageIntro.alt : "Intro image"}
            />
          </div>

          <div className='intro-content w-full xl:w-4/12'>
            <RichText
              render={product.data.intro_content.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
        </div>

        <div className='w-11/12 xl:max-w-screen-xl flex flex-row items-center xl:justify-end mx-auto mt-6 xl:mt-0 mb-24'>
          <GatsbyImage
            image={imageCert}
            alt={imageCert.alt ? imageCert.alt : "Certification image"}
          />
        </div>

        {product.data.intro_youtube_video_id
          ?
          <div className='w-11/12 xl:max-w-screen-md mx-auto pb-24'>
            <div className='flex flex-col items-center justify-center'>
              <div className='w-full shadow-md'>
                <YoutubePlayerLite youtubeID={product.data.intro_youtube_video_id} />
              </div>
            </div>

            <p className='text-xl md:text-2xl text-center mt-7'>{product.data.intro_youtube_video_text ? product.data.intro_youtube_video_text : null}</p>
          </div>
          :
          null
        }

        <div className='bg-site-gray-300 pt-24 pb-16'>
          <div className='w-11/12 xl:max-w-screen-xl grid grid-cols-1 lg:grid-cols-2 gap-24 xl:gap-0 mx-auto'>
            <div>
              <h2 className='font-body text-6xl md:text-7xl text-gray-900 font-bold mb-12'>{product.data.features_section_title ? product.data.features_section_title : "Section Title"}</h2>
            </div>

            <div className="features-content">
              <RichText
                render={product.data.features_section_content.richText}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>

          {imageFeatures
            ?
            <div className='w-11/12 xl:max-w-screen-xl flex items-center justify-center mt-16 mx-auto mb-4'>
              <GatsbyImage
                image={imageFeatures}
                alt="Features image"
              />
            </div>
            :
            null
          }
        </div>

        {product.data.show_section
          ?
          <div className='w-11/12 xl:max-w-screen-xl mx-auto pt-24'>
            {product.data.first_question_content
              ?
              <div className='flex flex-col xl:flex-row items-center mx-auto space-x-0 xl:space-x-20 mb-24'>
                <div className='w-full xl:w-6/12'>
                  <GatsbyImage
                    image={imageFirst}
                    alt="First image"
                  />
                </div>

                <div className='question-content w-full xl:w-6/12'>
                  <RichText
                    render={product.data.first_question_content.richText}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
              </div>
              :
              null
            }

            {product.data.second_question_content
              ?
              <div className='flex flex-col-reverse xl:flex-row items-center mx-auto space-x-0 xl:space-x-20'>
                <div className='question-content w-full xl:w-6/12'>
                  <RichText
                    render={product.data.second_question_content.richText}
                    htmlSerializer={htmlSerializer}
                  />
                </div>

                <div className='w-full xl:w-6/12'>
                  <GatsbyImage
                    image={imageSecond}
                    alt="Second image"
                  />
                </div>
              </div>
              :
              null
            }
          </div>
          :
          null
        }

        <div id="contact" className='w-full xl:max-w-screen-xl bg-gray-200 py-12 mt-24 mx-auto mb-0 xl:mb-20'>
          <div className='w-11/12 xl:max-w-screen-lg mx-auto mt-4 mb-4'>
            <div className='flex flex-col items-center justify-center'>
              <div className='bg-white w-full shadow-md pt-8 px-8 pb-4'>
                <HubspotForm
                  region='na1'
                  portalId='9298297'
                  formId={product.data.hubspot_form_id}
                  loading={<div className='italic'>Loading contact form...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  )
}

export default withPrismicPreview(CampaignLandingPageVersionTwoTemplate)

export const campaignLandingPageVersionTwoTemplateQueryNew = graphql`
  query($id: String!) {
    prismicCampaignLandingPageVersionTwo(id: { eq: $id }) {      
      _previewable
      uid
      data {
        page_title
        meta_title
        meta_description
        hero_title
        hero_sub_title
        hero_button_text
        hero_button_link {
          url
        }        
        hero_product_image {
          gatsbyImageData(width: 420)
          alt
        }
        hero_background_image {
          gatsbyImageData(width: 1920)
          alt
        }
        intro_content {
          richText
        }
        intro_product_image {
          gatsbyImageData(width: 800)
          alt
        }
        intro_badges {
          gatsbyImageData(width: 610)
          alt
        }
        intro_youtube_video_id
        intro_youtube_video_text
        features_section_title
        features_section_content {
          richText
        }
        features_section_image {
          gatsbyImageData(width: 1406)
          alt
        }
        show_section
        first_question_content {
          richText
        }
        first_question_image {
          gatsbyImageData(width: 800)
          alt
        }
        second_question_content {
          richText
        }
        second_question_image {
          gatsbyImageData(width: 800)
          alt
        }
        hubspot_form_id
      }
    }
  }
`