import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledGatsbyImage = styled(GatsbyImage)`
  height: 550px;

  @media (max-width: 768px) {
    height: auto;
  }
`

const PageHeaderImageBgNewUpdate = ({ children, imageData, opacity, height, position }) => {
  const data = useStaticQuery(graphql`
    query PageHeaderImageBgNewUpdateQuery {
      imageBg: file(relativePath: { eq: "header/CL-background.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
    }
  `);

  const imageBg = getImage(data.imageBg);

  return (
    <div className="grid relative mb-3" style={{ position: 'relative' }}>
      <StyledGatsbyImage
        style={{
          gridArea: '1/1',
          objectFit: 'cover',
          opacity: opacity ? opacity : 'none',
          width: '100%',
        }}
        alt=""
        image={imageData ? imageData : imageBg}
        formats={['auto', 'webp', 'avif']}
        width={1366}
        objectPosition={position ? position : 'top center'}
      />
      <div
        style={{
          gridArea: '1/1',
          position: 'relative',
          placeItems: 'center',
          display: 'grid',
          zIndex: 2,
        }}
      >
        {children}
      </div>
    </div>
  );
};

PageHeaderImageBgNewUpdate.propTypes = {
  children: PropTypes.node,
  imageData: PropTypes.object,
};

export default PageHeaderImageBgNewUpdate;