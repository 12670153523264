import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Menu, Transition } from '@headlessui/react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

import UpdateLogoNew from '../common/UpdateLogoNew'
import ContactImageNew from '../common/ContactImageNew'
import SearchButton from '../search/SearchButton'
import MobileDrawer from './MobileDrawer'

const AnnouncementText = loadable(() => import('./AnnouncementText'));

const Search = loadable(() => pMinDelay(import('../search'), 3000))

const searchIndices = [{ name: `Products`, title: `Products` }]

const menuLink = 'font-display font-medium text-lg text-red-100 uppercase tracking-widest hover:underline cursor-pointer'

const products = [
  { name: 'Command Packages', link: '/command-packages', desc: 'All-In-One Kits by Command Access' },
  { name: 'Low Energy Operator', link: '/low-energy-operator', desc: 'Low Energy Operator by Command Access' },
  { name: 'Mortise Locks', link: '/mortise-locks', desc: 'Electrified Mortise Locks' },
  { name: 'Cylindrical Locks', link: '/cylindrical-locks', desc: 'Electrified Cylindrical Locks' },
  { name: 'Exit Device Kits', link: '/exit-device-kits', desc: 'Motorized & Solenoid Latch Pullback Kits' },
  { name: 'Exit Devices', link: '/exit-devices', desc: 'Motorized & Electrified Exit Devices' },
  { name: 'Exit Trims', link: '/exit-trims', desc: 'Electrified Exit Trims' },
  { name: 'Power Supplies', link: '/power-supplies', desc: 'Power Supplies, Power Sources & Relay Boards' },
  { name: 'Power Transfers', link: '/power-transfers', desc: 'Energy Transfer Hinges & Door Loops' },
  { name: 'Accessories', link: '/accessories', desc: 'Accessories for Electrified Hardware' },
]

const HeaderNavNewVersion = () => {
  const data = useStaticQuery(graphql`
    query HeaderNavNewVersionQuery {
      prismicHomePage {
        data {
          announcement_section_active
        }
      }
    }
  `)

  const doc = data.prismicHomePage.data

  return (
    <>
      {doc.announcement_section_active
        ?
        <div className='bg-gray-900 z-50'>
          <div className="w-11/12 xl:max-w-screen-xl h-20 md:h-12 flex items-center justify-center lg:py-2 py-5 mx-auto">
            <AnnouncementText />
          </div>
        </div>
        :
        null
      }

      <div className='bg-red-900 border-b-0 xl:border-b xl:border-gray-900 border-t-4 xl:border-t-0 border-red-900 z-50'>
        <nav className="w-11/12 xl:max-w-screen-xl flex items-center justify-between bg-red-900 lg:py-2 py-5 mx-auto">
          <div className="w-40 h-auto hidden xl:block mr-6">
            <Link to="/">
              <UpdateLogoNew />
            </Link>
          </div>

          <div className="w-24 h-auto xl:hidden">
            <Link to="/">
              <UpdateLogoNew />
            </Link>
          </div>

          <div className="hidden xl:flex flex-col flex-grow mx-16">
            <div className='relative'>
              <h2 className="font-display font-hairline text-red-100 text-2xl uppercase text-center tracking-widest">
                Manufacturer of <span className="text-3xl font-black">Electrified</span> Locking Hardware
              </h2>
            </div>

            <hr className="border border-red-700" />

            <div className="flex items-center justify-between mt-2 mx-4">
              <Link to="/" className={menuLink}>
                Home
              </Link>

              <div className="relative">
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button className="group inline-flex items-center space-x-1 focus:outline-none">
                        <span className='font-display font-medium text-lg text-red-100 uppercase tracking-widest cursor-pointer'>Products</span>
                        <svg className="text-white fill-current h-5 w-5 group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                      </Menu.Button>

                      {/* Use the Transition + open render prop argument to add transitions. */}
                      <Transition
                        show={open}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 translate-y-1"
                        enterTo="transform opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="transform opacity-100 translate-y-0"
                        leaveTo="transform opacity-0 translate-y-1"
                      >
                        <Menu.Items static className="absolute -ml-4 mt-3 transform focus:outline-none w-screen max-w-2xl z-50">
                        <div className="rounded-lg shadow-lg">
                          <div className="rounded-lg shadow-xs overflow-hidden">
                            <div className="z-20 relative grid grid-cols-2 gap-8 bg-white px-5 py-6">
                              {products.map((product, index) => {
                                return (
                                  <Link
                                    to={product.link}
                                    key={index}
                                    className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-200 transition ease-in-out duration-150"
                                  >
                                    <Menu.Item className="space-y-1">
                                      <div className='flex flex-col'>
                                        <p className="text-base leading-6 font-bold text-red-900">
                                          {product.name}
                                        </p>
                                        <p className="text-sm leading-5 text-gray-500">
                                          {product.desc}
                                        </p>
                                      </div>
                                    </Menu.Item>
                                  </Link>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>

              <div className="relative">
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button className="group inline-flex items-center space-x-1 focus:outline-none">
                        <span className='font-display font-medium text-lg text-red-100 uppercase tracking-widest cursor-pointer'>Resources</span>
                        <svg className="text-white fill-current h-5 w-5 group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                      </Menu.Button>

                      {/* Use the Transition + open render prop argument to add transitions. */}
                      <Transition
                        show={open}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 translate-y-1"
                        enterTo="transform opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="transform opacity-100 translate-y-0"
                        leaveTo="transform opacity-0 translate-y-1"
                      >
                        <Menu.Items static className="absolute -ml-4 mt-3 transform focus:outline-none w-screen max-w-2xl z-50">
                        <div className="rounded-lg shadow-lg">
                          <div className="rounded-lg shadow-xs overflow-hidden">
                            <div className="z-20 relative grid grid-cols-2 gap-8 bg-white px-5 py-6">
                              <a
                                href='https://www.commandaccess.com/forms/'
                                className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-200 transition ease-in-out duration-150"
                              >
                                <Menu.Item className="space-y-1">
                                  <div className='flex flex-col'>
                                    <p className="text-base leading-6 font-bold text-red-900">
                                      Forms & Documents
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                      Check Command Access Forms & Documents that you can request and download.
                                    </p>
                                  </div>
                                </Menu.Item>
                              </a>
                              <a
                                href='https://www.commandaccess.com/distributors/'
                                className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-200 transition ease-in-out duration-150"
                              >
                                <Menu.Item className="space-y-1">
                                  <div className='flex flex-col'>
                                    <p className="text-base leading-6 font-bold text-red-900">
                                      Product Distributors
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                      Find Command Access distributors in or near your area.
                                    </p>
                                  </div>
                                </Menu.Item>
                              </a>
                              <a
                                href='https://www.commandaccess.com/knowledgebase/'
                                className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-200 transition ease-in-out duration-150"
                              >
                                <Menu.Item className="space-y-1">
                                  <div className='flex flex-col'>
                                    <p className="text-base leading-6 font-bold text-red-900">
                                      Knowledge Base
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                      We’re always adding new content including answers to common problems.
                                    </p>
                                  </div>
                                </Menu.Item>
                              </a>
                              <a
                                href='https://www.commandaccess.com/sales-representatives/'
                                className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-200 transition ease-in-out duration-150"
                              >
                                <Menu.Item className="space-y-1">
                                  <div className='flex flex-col'>
                                    <p className="text-base leading-6 font-bold text-red-900">
                                      Sales Representatives
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                      Find Command Access sales representatives in or near your area.
                                    </p>
                                  </div>
                                </Menu.Item>
                              </a>
                              <a
                                href='https://www.commandaccess.com/blog/'
                                className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-200 transition ease-in-out duration-150"
                              >
                                <Menu.Item className="space-y-1">
                                  <div className='flex flex-col'>
                                    <p className="text-base leading-6 font-bold text-red-900">
                                      Blog
                                    </p>
                                    <p className="text-sm leading-5 text-gray-500">
                                      Learn about our thoughts and perspectives on a range other than industry specific topics.
                                    </p>
                                  </div>
                                </Menu.Item>
                              </a>
                            </div>
                          </div>
                        </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>

              <Link to="/about" className={menuLink}>
                About Us
              </Link>

              <Link to="/contact" className={menuLink}>
                Contact
              </Link>

              <SearchButton />
            </div>
          </div>

          <div className="hidden xl:flex items-center w-auto">
            <a href="tel:888-622-2377">
              <ContactImageNew />
            </a>
          </div>

          <div className='xl:hidden relative flex flex-grow items-center justify-end'>
            <div className='absolute'>
              <Search indices={searchIndices} />
            </div>
          </div>

          <MobileDrawer />
        </nav>
      </div>
    </>
  )
}

export default HeaderNavNewVersion