import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledDiv = styled.div`
  .chevron::before {
    border-style: solid;
    border-color: #fafafa;
    border-width: 0.50em 0.50em 0 0;
    content: '';
    height: 3.7em;
    position: absolute;
    top: 6px;
    bottom: 0;
    transform: rotate(-45deg);
    width: 3.7em;
  }

  .chevron.right:before {
    transition: right 0.5s ease;
    right: 25px;
    transform: rotate(45deg);
  }

  &:hover {
    .chevron::before {
      right: 6px;
    }
  }
`

const ButtonWithImageNew = ({ imageData, title, productName }) => {
  const data = useStaticQuery(graphql`
    query ButtonWithImageNewQuery {
      imageBg: file(relativePath: { eq: "pages/cutsheet-bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 998
          )
        }
      }
    }
  `)

  const imageBg = getImage(data.imageBg)

  return (
    <StyledDiv className="relative mb-3" style={{ display: "grid", background: "#423E42" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <GatsbyImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          height: 70,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        image={ imageData ? imageData : imageBg }
        formats={["auto", "webp", "avif"]}
        width={998}
        objectPosition="top center"
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "start",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div className='flex items-center py-4 md:p-4'>
          <h2 className='font-display text-3xl text-white ml-3 md:ml-0 mr-2 lead'>{ title ? title : 'Button Name' }</h2>
          <h2 className='font-display text-xl text-white'>{ productName ? productName : 'Product Name' }</h2>
        </div>

        <div className='chevron right' />
      </div>
    </StyledDiv>
  )
}

ButtonWithImageNew.propTypes = {
  imageData: PropTypes.object,
  title: PropTypes.string,
  productName: PropTypes.string,
}

export default ButtonWithImageNew
