import React from 'react'
import PropTypes from 'prop-types'
import { LiteYoutubeEmbed } from 'react-lite-yt-embed'

const YoutubePlayerLite = ({ youtubeID }) => (
  <div className='shadow-lg'>
    <LiteYoutubeEmbed id={youtubeID ? youtubeID : '20EwgqP00BE'} />
  </div>
)

export default YoutubePlayerLite

YoutubePlayerLite.propTypes = {
  youtubeID: PropTypes.string,
}