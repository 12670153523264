import loadable from '@loadable/component';

const LoadableGtmBody = loadable(() =>
  import('./GtmBody').catch(() => {
    // Fallback in case of import error or timeout
    console.error('Failed to load GTM body script.');
    return () => null;
  })
);

export default LoadableGtmBody;